<template>
  <div class="teachers-missing__form">
    <vs-select label="Missing cause" v-model="cause_id">
      <vs-select-item
        :key="index"
        :value="item.id"
        :text="item.name"
        v-for="(item, index) in causes"
      />
    </vs-select>
    <div id="missing-timeperiod">
      <span class="birhdate__placeholder">Missing period</span>
      <v-date-picker v-model="range" is-range is-inline />
    </div>
    <vs-textarea label="Comment" v-model="comment" />

    <div class="save-popup-button">
      <vs-button icon="save" @click="saveMissing">Save</vs-button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TeacherMissingForm",
  props: ["selectedMissing"],
  data() {
    let data = {
      range: {},
      comment: "",
      cause_id: null
    };
    if (this.selectedMissing.id) {
      data.range = {
        start: this.selectedMissing.date_start.toDate(),
        end: this.selectedMissing.date_end.toDate()
      };
      data.comment = this.selectedMissing.comment;
      data.cause_id = this.selectedMissing.cause.id;
    }

    return { ...data };
  },
  computed: {
    ...mapState({
      teacher: state => state.teachers.singleTeacher,
      causes: state => state.teachers.causes
    })
  },
  methods: {
    saveMissing: function() {
      // TODO: avatar to base64
      const data = {
        cause_id: this.cause_id,
        teacher_id: this.teacher.id,
        comment: this.comment,
        date_start: this.range.start.getTime() / 1000,
        date_end: this.range.end.getTime() / 1000
      };
      if (this.selectedMissing) data.id = this.selectedMissing.id;
      this.$store
        .dispatch("teachers/saveTeachersMissingAction", {
          missing: data
        })
        .then(() => {
          this.$emit("closePopup", this.closePopup);
        })
        .catch(() => {
          this.$vs.notify({
            title: "Error",
            text: "Error in save missing",
            color: "danger",
            position: "top-right"
          });
        });
    }
  }
};
</script>

<style lang="scss">
.teachers-missing__form {
  & > * {
    margin: 20px 10px;
  }
  .vs-con-textarea {
    width: auto;
  }
  .con-select {
    width: auto;
  }
}
</style>