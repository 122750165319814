var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"teachers__list"},[_c('vs-table',{attrs:{"stripe":"","search":"","data":_vm.teachers},on:{"dblSelection":_vm.doubleSelection},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:tr.id,attrs:{"data":tr}},[_c('vs-td',{attrs:{"data":data[indextr].nickname}},[_vm._v(" "+_vm._s(tr.weight)+". "),_c('vs-avatar',{attrs:{"src":data[indextr].options
                ? (_vm.proxy + "/static/files/" + (data[indextr].options.avatar))
                : null}}),_vm._v(" "+_vm._s(data[indextr].nickname)+" ")],1),_c('vs-td',{attrs:{"data":data[indextr].phone}},[_vm._v(_vm._s(data[indextr].phone))]),_c('vs-td',{attrs:{"data":data[indextr].id}},[_c('vs-button',{attrs:{"data-id":data[indextr].id,"radius":"","color":"dark","icon":"visibility","type":"flat"},on:{"click":function($event){return _vm.$router.push({
                name: 'Teacher',
                params: { teacher_id: data[indextr].id },
              })}}}),_c('vs-button',{attrs:{"data-id":data[indextr].id,"radius":"","color":"dark","icon":"edit","type":"flat"},on:{"click":function($event){return _vm.editTeacherHandle(data[indextr].id)}}}),_c('vs-button',{attrs:{"data-id":data[indextr].id,"radius":"","color":"dark","icon":"delete","type":"flat"},on:{"click":function($event){return _vm.deleteTeacher(data[indextr].id)}}})],1)],1)})}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"header"},[_c('h3',[_vm._v("Teachers ("+_vm._s(_vm.teachers.length)+")")]),_c('div',{staticClass:"teachers__filter"},[_c('div',{staticClass:"label"},[_vm._v("Status")]),_c('vs-select',{on:{"change":_vm.updateStatusHandler},model:{value:(_vm.selfStatus),callback:function ($$v) {_vm.selfStatus=$$v},expression:"selfStatus"}},[_c('vs-select-item',{attrs:{"value":"0","text":"Deactivated"}}),_c('vs-select-item',{attrs:{"value":"1","text":"Active"}})],1)],1)]),_c('template',{slot:"thead"},[_c('vs-th',{attrs:{"sort-key":"name"}},[_vm._v("Name")]),_c('vs-th',{attrs:{"sort-key":"phone"}},[_vm._v("Phone")]),_c('vs-th')],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }