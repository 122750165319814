<template>
  <div class="teachers__list">
    <vs-table
      stripe
      v-model="selected"
      search
      @dblSelection="doubleSelection"
      :data="teachers"
    >
      <template slot="header">
        <h3>Teachers ({{ teachers.length }})</h3>
        <div class="teachers__filter">
          <div class="label">Status</div>
          <vs-select v-model="selfStatus" @change="updateStatusHandler">
            <vs-select-item value="0" text="Deactivated" />
            <vs-select-item value="1" text="Active" />
          </vs-select>
        </div>
      </template>
      <template slot="thead">
        <vs-th sort-key="name">Name</vs-th>
        <vs-th sort-key="phone">Phone</vs-th>
        <vs-th></vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="tr.id" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].nickname">
            {{ tr.weight }}.
            <vs-avatar
              :src="
                data[indextr].options
                  ? `${proxy}/static/files/${data[indextr].options.avatar}`
                  : null
              "
            />
            {{ data[indextr].nickname }}
          </vs-td>

          <vs-td :data="data[indextr].phone">{{ data[indextr].phone }}</vs-td>
          <vs-td :data="data[indextr].id">
            <vs-button
              :data-id="data[indextr].id"
              radius
              color="dark"
              icon="visibility"
              type="flat"
              @click="
                $router.push({
                  name: 'Teacher',
                  params: { teacher_id: data[indextr].id },
                })
              "
            ></vs-button>
            <vs-button
              :data-id="data[indextr].id"
              radius
              color="dark"
              icon="edit"
              type="flat"
              @click="editTeacherHandle(data[indextr].id)"
            ></vs-button>
            <vs-button
              :data-id="data[indextr].id"
              radius
              color="dark"
              icon="delete"
              type="flat"
              @click="deleteTeacher(data[indextr].id)"
            ></vs-button>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: ["status"],
  data() {
    return {
      selfStatus: this.status,
      selected: [],
      selectedId: null,
      proxy: process.env.VUE_APP_PROXY,
      api_url: process.env.VUE_APP_API_URI,
    };
  },
  computed: {
    ...mapState({
      teachers: (state) => state.teachers.teachers,
    }),
  },
  methods: {
    deleteTeacher: function (id) {
      this.selectedId = id;
      this.openAlert();
    },
    editTeacherHandle: function (id) {
      this.$emit("editTeacher", id);
    },
    doubleSelection: function (tr) {
      this.$router.push({ name: "Teacher", params: { teacher_id: tr.id } });
    },
    openAlert: function () {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: "Delete teacher",
        text: "Are you sure you want to delete the teacher?",
        accept: this.acceptAlert,
      });
    },
    acceptAlert: function () {
      this.$emit("deleteTeacher", this.selectedId);
      this.selectedId = null;
    },
    updateStatusHandler: function (status) {
      this.$emit("updateStatus", status);
    },
  },
};
</script>

<style>
</style>