<template>
  <div class="teacher-notes">
    <div
      class="teacher-notes-group"
      v-for="(group, index) in Object.values(groupNotes)"
      :key="index"
    >
      <vs-collapse type="margin">
        <vs-collapse-item :open="true">
          <div slot="header">
            <group :id="group.group.id" :color="group.group.color">{{
              group.group.name
            }}</group>
          </div>

          <div
            class="teacher-notes-lessons"
            v-for="(lesson, index) in group.lessons"
            :key="index"
          >
            <lesson-link :lesson="lesson.lesson" />
            <note-item
              v-for="note in lesson.notes"
              :key="note.id"
              :note="note"
              @updateNoteList="updateNoteList"
            />
          </div>
        </vs-collapse-item>
      </vs-collapse>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Group from "../../common/elements/group.vue";
import NoteItem from "./noteItem.vue";
import LessonLink from "../../common/elements/lessonLink.vue";
export default {
  props: ["teacher_id"],
  components: {
    NoteItem,
    Group,
    LessonLink,
  },
  computed: {
    ...mapState({
      notes: (state) => state.teachers.notes,
    }),
    groupNotes: function () {
      if (!this.notes) return {};

      const groupByLesson = this.notes.reduce((output, note) => {
        if (note.lesson.id in output) {
          output[note.lesson.id].notes.push(note);
        } else {
          output[note.lesson.id] = {
            lesson: note.lesson,
            group: note.group,
            notes: [note],
          };
        }
        return output;
      }, {});

      const groupByGroup = Object.values(groupByLesson).reduce(
        (output, item) => {
          if (!item.group.id) {
            // TODO: set tutor and testing
            return output;
          }
          const { group, lesson, notes } = item;
          if (group.id in output) {
            output[group.id].lessons.push({
              lesson,
              notes,
            });
          } else {
            output[group.id] = {
              group,
              lessons: [
                {
                  lesson,
                  notes,
                },
              ],
            };
          }
          return output;
        },
        {},
      );
      return groupByGroup;
    },
  },
  created: function () {
    this.getNotes();
  },
  methods: {
    getNotes: async function () {
      const { teacher_id } = this;
      await this.$store.dispatch("teachers/getNotesAction", { teacher_id });
    },
    updateNoteList: function () {
      console.log("update");
      this.getNotes();
    },
  },
};
</script>

<style lang="scss">
.teacher-notes {
  &-lessons {
    padding: 0.5rem 0 0.5rem 0.5rem;
  }
  .open-item .vs-collapse-item--content {
    max-height: none !important;
  }
}
</style>