<template>
  <div class="teachers__form">
    <div class="field">
      <label for="">NickName</label>
      <vs-input size="large" v-model="nickname"></vs-input>
    </div>

    <div class="field">
      <label for="">Frist name</label>
      <vs-input size="large" v-model="first_name"></vs-input>
    </div>
    <div class="field">
      <label for="">Last name</label>
      <vs-input size="large" v-model="last_name"></vs-input>
    </div>
    <div class="field">
      <label for="">Middle name</label>
      <vs-input size="large" v-model="middle_name"></vs-input>
    </div>
    <div class="field">
      <label for="">Teacher weight</label>
      <vs-input size="large" v-model="weight"></vs-input>
    </div>
    <div class="field">
      <vs-textarea label="Description" />
    </div>
    <div class="field">
      <label for="">Active</label>
      <vs-switch v-model="is_active" />
    </div>
    <hr />
    <div id="birthdate">
      <span class="birhdate__placeholder" :class="{ full: birthdate }"
        >Birthdate</span
      >
      <v-date-picker v-model="birthdate" />
    </div>

    <hr />
    <vs-input
      size="large"
      label-placeholder="Phones"
      v-model="phone"
    ></vs-input>
    <hr />
    <vs-input
      size="large"
      label-placeholder="Fixed Salary"
      v-model="fixed_salary"
    ></vs-input>
    <vs-input size="large" label-placeholder="Rate" v-model="rate"></vs-input>
    <vs-input
      type="password"
      :success="teacher.password_exist"
      success-text="password exist"
      size="large"
      label-placeholder="Password"
      v-model="password"
    ></vs-input>
    <vs-avatar :src="avatar" />
    <vs-upload :show-upload-button="false" @change="changeFile" />
    <hr />
    <div class="roles">
      <h3>Roles</h3>
      <vs-checkbox
        v-if="$can('manage', 'roles')"
        v-model="roles"
        vs-value="admin"
        >Admin</vs-checkbox
      >
      <vs-checkbox
        v-if="$can('manage', 'roles')"
        v-model="roles"
        vs-value="operator"
        >Operator</vs-checkbox
      >
    </div>

    <div class="save-popup-button">
      <vs-button icon="save" @click="saveTeacher">Save</vs-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "TeacherForm",
  props: ["teacher"],
  data() {
    let data = {
      nickname: "",
      first_name: "",
      last_name: "",
      middle_name: "",
      fixed_salary: 0,
      birthdate: "",
      rate: 0,
      avatar: "",
      phone: "",
      weight: 0,
      password: "",
      roles: [],
      is_acitve: true,
    };
    if (this.teacher.id) {
      data = { ...this.teacher };
      data.password = "";
      data.roles = data.options ? data.options.roles || [] : [];
    }

    return { ...data };
  },
  methods: {
    saveTeacher: function () {
      // TODO: avatar to base64
      let {
        nickname,
        first_name,
        last_name,
        middle_name,
        fixed_salary,
        birthdate,
        rate,
        avatar,
        phone,
        roles,
        weight,
        is_active,
      } = this;

      birthdate = birthdate ? birthdate.getTime() / 1000 : null;

      const data = {
        nickname,
        first_name,
        last_name,
        middle_name,
        fixed_salary,
        birthdate,
        rate,
        avatar,
        phone,
        roles,
        weight,
        is_active,
      };
      if (this.password) {
        data.password = this.password;
      }
      if (this.id) data.id = this.id;
      this.$store
        .dispatch("teachers/saveTeachersAction", {
          teacher: data,
        })
        .then(() => {
          this.$emit("closePopup", this.closePopup);
          const user = JSON.parse(localStorage.getItem("user"));
          if (data.id == user.id) {
            localStorage.setItem(
              "user",
              JSON.stringify({
                id: data.id,
                nickname: data.nickname,
                options: this.teacher.options,
                weight: data.weight,
              }),
            );
          }
        });
    },
    changeFile: function (event, files) {
      this.toBase64(files[0]).then((f) => {
        this.avatar = f;
      });
    },
    toBase64: function (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
  },
};
</script>

<style lang="scss">
.teachers__form {
  .vs-input {
    width: 60%;
    margin-bottom: 25px;
    // .vs-input--input {
    //   padding: 10px;
    // }
  }
  hr {
    margin: 25px 0;
    color: #eee;
  }
  .roles {
    & > div {
      align-items: flex-start;
      justify-content: flex-start;
      padding: 10px 0;
    }
  }
}
</style>