<template>
  <router-link
    :to="{
      name: 'Lessons',
      params: { lesson_id: lesson.id },
    }"
    class="lesson-link"
  >
    {{ lesson.name }}
  </router-link>
</template>

<script>
export default {
  props: ["lesson"],
};
</script>

<style>
</style>