<template>
  <div class="missings">
    <div class="missings_add-button">
      <vs-button
        icon="add"
        type="flat"
        @click="$emit('addOrEditMissing')"
        v-if="$can('manage', 'teacher')"
        >Add</vs-button
      >
    </div>
    <div class="missings-item" v-for="missing in missings" :key="missing.id">
      <div class="missings-item__info">
        <div class="missing-item__title">{{ missing.cause.name }}</div>
        <div class="missing-item__dates">
          {{ missing.date_start_string }} -
          {{ missing.date_end_string }} (<strong>{{
            missing.day_count
          }}</strong>
          days)
        </div>
        <div class="missing-item__comment" v-if="missing.comment">
          {{ missing.comment }}
        </div>
      </div>
      <div class="missings-item__manage" v-if="$can('manage', 'teacher')">
        <vs-button
          icon="edit"
          type="flat"
          color="dark"
          radius
          @click="editMissingHandle($event, missing)"
        ></vs-button>
        <vs-button
          icon="delete"
          type="flat"
          color="dark"
          radius
          @click="deleteMissingHandle($event, missing)"
        ></vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TeacherMissingTab",
  props: ["schoolyearId"],
  data() {
    return {
      data: [],
    };
  },
  computed: {
    ...mapState({
      teacher: (state) => state.teachers.singleTeacher,
      causes: (state) => state.teachers.causes,
      missings: (state) => state.teachers.missings,
      singleMissing: (state) => state.teachers.singleMissing,
    }),
  },
  methods: {
    editMissingHandle: function (e, missing) {
      e.preventDefault();
      this.$emit("addOrEditMissing", missing);
    },
    deleteMissingHandle: function (e, missing) {
      e.preventDefault();
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm delete missing`,
        text: `Are You shure to delete missing of ${missing.teacher.nickname}`,
        accept: function () {
          this.$store.dispatch("teachers/deleteMissingAction", {
            teacher_id: missing.teacher.id,
            id: missing.id,
          });
        }.bind(this),
      });
    },
  },
  created: function () {
    this.$store.dispatch("teachers/getTeacherMissingsAction", {
      schoolyear: this.schoolyearId,
      teacherId: this.teacher.id,
    });
  },
};
</script>

<style lang="scss">
.missings {
  .missings_add-button {
    .vs-button {
      float: none;
    }
  }
  .missings-item {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    margin: 7px;
    padding: 7px;
    .missings-item__info {
      flex: 1;
      .missing-item__title {
        font-size: 1.1em;
        font-weight: bold;
      }
      .missing-item__dates {
        font-size: 0.9em;
        color: #3e3e3e;
        padding-left: 15px;
      }
      .missing-item__comment {
        padding: 10px;
        margin-left: 15px;
        background-color: #eee;
        border-radius: 10px;
      }
    }
  }
}
</style>