<template>
  <div class="teachers__export-button">
    <div class="teachers__dropdown">
      <vs-button
        class="btnx vs-con-loading__container"
        type="flat"
        icon="download"
        @click="downloadTimeSheet(prevMonth)"
        :disabled="downloading"
        ref="loadableButton"
        id="button-with-loading"
        >Export timesheet ({{ prevMonth.format("MMMM YYYY") }})</vs-button
      >
      <vs-dropdown vs-trigger-click>
        <vs-button class="btn-drop" type="flat" icon="expand_more"></vs-button>
        <vs-dropdown-menu class="teachers__export__timesheet">
          <vs-dropdown-item @click="downloadTimeSheet(prevMonth)">
            Export prev ({{ prevMonth.format("MMMM YYYY") }})
          </vs-dropdown-item>
          <vs-dropdown-item divider @click="downloadTimeSheet(now)">
            Export current ({{ now.format("MMMM YYYY") }})
          </vs-dropdown-item>
          <vs-dropdown-group vs-label="Other months">
            <vs-dropdown-item
              v-for="(month, index) in months"
              :key="index"
              @click="downloadTimeSheet(month)"
              >Export {{ month.format("MMMM YYYY") }}
            </vs-dropdown-item>
          </vs-dropdown-group>
        </vs-dropdown-menu>
      </vs-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      now: this.$moment(),
      downloading: false,
    };
  },
  computed: {
    ...mapState({
      currentYear: (state) => state.years.currentSchoolYear,
    }),
    months: function () {
      if (this.currentYear) {
        const start = this.$moment(this.currentYear.date_start_unix * 1000);
        return Array(12)
          .fill(null)
          .map((m, index) => {
            return start.clone().add("month", index);
          });
      }
      return [];
    },
    prevMonth: function () {
      return this.now.clone().add("month", -1);
    },
  },
  methods: {
    downloadTimeSheet: function (date) {
      this.downloading = true;
      this.$vs.loading({
        background: "primary",
        color: "#fff",
        container: this.$refs.loadableButton.$el,
        scale: 0.45,
      });
      this.$store
        .dispatch("teachers/downloadTimeSheetAction", {
          month: date.month() + 1,
          year: date.year(),
        })
        .then((result) => {
          const url = "data:application/vnd.ms-excel;base64," + result.body;
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `timesheet_${date.format("MMMM_YYYY")}.xls`,
          );
          link.style.display = "none";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);

          this.downloading = false;
          this.$vs.loading.close(this.$refs.loadableButton.$el);
        })
        .catch((e) => {
          this.$vs.notify({
            title: "Error on download",
            text: e.message,
            color: "danger",
            position: "top-right",
          });
          this.downloading = false;
          this.$vs.loading.close(this.$refs.loadableButton.$el);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.teachers__dropdown {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  .btnx {
    margin-left: 10px !important;
    border-radius: 5px 0px 0px 5px;
  }
  .btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, 0.2);
  }
}
.teachers__export__timesheet {
  min-width: 300px;
}
</style>