<template>
  <div class="student">
    <div class="status-bar">
      <div class="status-bar__back-button">
        <vs-button
          icon="arrow_back"
          class
          type="flat"
          color="dark"
          to="/teachers"
          title="back to teacher's list"
        ></vs-button>
      </div>
      <div class="status-bar__back-button">
        <vs-button
          icon="navigate_before"
          class
          type="flat"
          color="dark"
          :title="`Go to previous teacher ${prevTeacher.teacherName}`"
          v-if="prevTeacher.name"
          @click="goToTeacher(prevTeacher)"
        ></vs-button>
      </div>
      <h2 class="status-bar__title">
        <vs-avatar
          :src="
            teacher.options
              ? `${proxy}/static/files/${teacher.options.avatar}`
              : null
          "
        />
        {{ teacher.nickname }}
      </h2>
      <div class="status-bar__back-button">
        <vs-button
          icon="navigate_next"
          class
          type="flat"
          color="dark"
          :title="`Go to next teacher ${nextTeacher.teacherName}`"
          v-if="nextTeacher.name"
          @click="goToTeacher(nextTeacher)"
        ></vs-button>
      </div>
      <div class="status-bar__manage-buttons full-size-button">
        <vs-button
          icon="edit"
          class="status-bar__edit-button"
          type="flat"
          color="dark"
          @click="popupActive = true"
          >Edit</vs-button
        >
      </div>
      <div class="status-bar__manage-buttons" v-if="isMyPage">
        <vs-button
          icon="exit_to_app"
          class="status-bar__logout-button"
          type="flat"
          color="dark"
          @click="logout"
          >Logout</vs-button
        >
      </div>
    </div>
    <div class="view-content">
      <Split>
        <SplitArea :size="75" class="teacher-table-split-block">
          <div class="tasks teacher-tasks">
            <tasks-wrapper place_type="teacher" :place_id="parseInt(id, 10)" />
          </div>
          <div class="schoolyears">
            <vs-select
              width="400px"
              class="selectExample"
              label="School Years"
              v-model="selectedYearId"
            >
              <vs-select-item
                :key="index"
                :value="year.id"
                :text="year.name"
                v-for="(year, index) in years"
              />
            </vs-select>
          </div>
          <!-- <vs-progress indeterminate color="primary" v-if="loader">primary</vs-progress> -->
          <div v-if="teacher.id">
            <TeacherAttendance
              :teacher="teacher"
              :missings="missings"
              :schoolyearId="selectedYearId"
            />
          </div>
          <div class="delimiter"></div>
          <new-timetable :teacher_id="id" />
        </SplitArea>
        <SplitArea :size="25">
          <vs-tabs>
            <vs-tab label="Comments">
              <div class="con-tab-ejemplo">
                <Comments
                  :itemId="$route.params.teacher_id"
                  itemType="Teachers"
                />
              </div>
            </vs-tab>
            <vs-tab label="My notes">
              <div class="con-tab-ejemplo">
                <notes-tab-wrapper :teacher_id="id" />
              </div>
            </vs-tab>
            <vs-tab label="Missings">
              <div class="con-tab-ejemplo" v-if="selectedYearId">
                <TeacherMissingTab
                  :schoolyearId="selectedYearId"
                  @addOrEditMissing="addOrEditMissingHandle"
                />
              </div>
            </vs-tab>
          </vs-tabs>
        </SplitArea>
      </Split>
    </div>
    <vs-popup
      class="form-popup students__popup"
      classContent="students__popup-content"
      title="Teacher"
      :active.sync="popupActive"
    >
      <TeacherForm
        v-if="popupActive"
        :teacher="teacher"
        @closePopup="closePopup"
      />
    </vs-popup>
    <vs-popup
      class="form-popup teacher-missing-popup small-popup"
      classContent="teacher__popup-content"
      title="Teacher missing edit"
      :active.sync="popupMissingsActive"
    >
      <TeacherMissingForm
        :selectedMissing="selectedMissing"
        @closePopup="closePopup"
        v-if="popupMissingsActive"
      />
    </vs-popup>
  </div>
</template>

<script>
import { mapState } from "vuex";
import TeacherForm from "@/components/teachers/teacherForm.vue";
import TeacherMissingTab from "@/components/teachers/teacherMissingTab.vue";
import Comments from "@/components/comments/comments.vue";
import TeacherMissingForm from "@/components/teachers/teacherMissingForm.vue";
import TeacherAttendance from "@/components/teachers/teacherAttendance";
import NewTimetable from "../components/timetable/newTimetable.vue";
import TasksWrapper from "../components/tasks/tasksWrapper.vue";
import NotesTabWrapper from "../components/teachers/notes/notesTabWrapper.vue";

export default {
  name: "TeacherView",
  title: "SE-Teacher",
  data() {
    return {
      popupActive: false,
      popupMissingsActive: false,
      id: this.$route.params.teacher_id,
      today: new Date(),
      schoolYears: {},
      groupsData: {},
      tableData: [],
      selectedMissing: {},
      selectedYearId: null,
      loader: true,
      proxy: process.env.VUE_APP_PROXY,
    };
  },
  components: {
    TeacherForm,
    Comments,
    TeacherMissingTab,
    TeacherMissingForm,
    TeacherAttendance,
    NewTimetable,
    TasksWrapper,
    NotesTabWrapper,
  },
  computed: {
    ...mapState({
      teacher: (state) => state.teachers.singleTeacher,
      teachers: (state) => state.teachers.teachers,
      missings: (state) => state.teachers.missings,
      groups: (state) => state.groups.groups,
      years: (state) => state.years.years,
      yearMonths: (state) => state.years.yearMonths,
      currentYear: (state) => state.years.currentSchoolYear,
    }),
    isMyPage: function () {
      const localUser = JSON.parse(localStorage.getItem("user"));
      return this.$route.params.teacher_id == localUser.id;
    },
    nextTeacher: function () {
      const selfIndex = this.teachers.findIndex((g) => g.id == this.id);
      if (this.teachers.length > selfIndex + 1) {
        const nextTeacher = this.teachers[selfIndex + 1];
        return {
          name: "Teacher",
          params: { teacher_id: nextTeacher.id },
          teacherName: nextTeacher.name,
        };
      }
      return {};
    },
    prevTeacher: function () {
      const selfIndex = this.teachers.findIndex((g) => g.id == this.id);
      if (selfIndex - 1 >= 0) {
        const prevTeacher = this.teachers[selfIndex - 1];
        return {
          name: "Teacher",
          params: { teacher_id: prevTeacher.id },
          teacherName: prevTeacher.nickname,
        };
      }
      return {};
    },
    selectedYear: function () {
      if (this.selectedYearId) {
        return this.years.find((y) => y.id == this.selectedYearId);
      }
      return {};
    },
  },
  methods: {
    logout: function () {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      window.location.reload();
    },
    closePopup: function () {
      this.popupActive = false;
      this.popupMissingsActive = false;
    },
    addOrEditMissingHandle: function (missing) {
      this.selectedMissing = missing || {};
      this.popupMissingsActive = true;
    },
    goToTeacher: function (targetTeacher) {
      this.$router.push(targetTeacher);
    },
    getTeacher: function () {
      this.$store
        .dispatch("teachers/getSingleTeacherAction", {
          id: this.$route.params.teacher_id,
        })
        .then(() => {
          // this.$store.dispatch("years/getYearsAction").then(() => {
          // this.setCurrentSchoolYear();
          this.$store.dispatch("teachers/getTeacherMissingsAction", {
            schoolyear: this.selectedYearId,
            teacherId: this.teacher.id,
          });
          // });
        });
      this.$store.dispatch("teachers/getMissingCausesAction");
    },
  },
  created: async function () {
    if (!this.teachers.length) {
      await this.$store.dispatch("teachers/getTeachersAction");
    }
    if (!this.years.length) {
      await this.$store.dispatch("years/getYearsAction");
    }
    this.selectedYearId = this.currentYear.id;
  },
  watch: {
    currentYear: async function (newV) {
      this.selectedYearId = newV.id;
    },
    selectedYearId: function () {
      this.getTeacher();
    },
  },
};
</script>

<style lang="scss">
.schoolyears {
  margin-bottom: 15px;
}
// .teacher-table-split-block {
//   overflow-x: auto;
// }
.delimiter {
  width: 100%;
  height: 2px;
  background-color: #eee;
  margin: 20px 0;
}
</style>